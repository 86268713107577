import React, { useState } from 'react';
import Gallery from './Gallery';
import initialImages from './images'

const getImages = (images) => {
  const [firstImage, secondImage, thirdImage, ...otherImages] = images

  otherImages.sort(() => Math.random() - 0.5);

  return [firstImage, secondImage, thirdImage, ...otherImages];
}
function App() {
  const [images, setImages] = useState(getImages(initialImages))
  const PASSWORD_LENGTH = 5

  const onChange = async ({ target }) => {
    const { value = '' } = target
    if (value.length === PASSWORD_LENGTH) {
      const response = await fetch(`https://meriemwithlove.herokuapp.com/${value}`)
      const data = await response.json()
      setImages(getImages([...data.images, ...images]))
    }
  }
  return (
    <div>
      <p className="message">
        <p>
          <span className="message-bold">مريومة</span> ... كل سنة وانت أحلى وأجمل (وأهدى)<br />
          كل سنة وابتسامتك حاضرة<br />
          وغمازاتك (والشامة في خدودك) دنيتي <br />
          كل سنة وضحكتك الرنانة اعلى<br />
          وقلبك الصافي مرتاح وراضي<br />
          كل سنة وانت سبب سعادتي وانا سبب سعادتك<br />
          كل سنة وحبنا مزدهر.<br />

        </p><p>
          كل سنة واحنا مع بعض<br />
          بكل الحب والاحترام والسعادة<br />
          يا امرأتي.

        </p>
      </p>
      <div className="password">
        <label for="password">Knock Knock .. Who's there?</label>
        <input type="password" name="password" autocomplete="off" placeholder="Answer me, Meriem" maxLength={PASSWORD_LENGTH} onChange={onChange} />
      </div>
      <Gallery elements={images} />
      <div className="message bottom">
        This site looks better on a bigger screen (or portrait mode)
      </div>
    </div>
  );
}

export default App;
