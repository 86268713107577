export default [{
    src: '/images/10.jpg',
    width: '2',
    height: '4'
},
{
    src: '/images/00.jpg',
    width: '2',
    height: '4'
},
{
    src: '/images/05.jpg',
    width: '',
    height: '4'
},
{
    src: '/images/12.jpg',
    width: '2',
    height: '4'
},
{
    src: '/images/15.jpg',
    width: '2',
    height: '2'
},
{
    src: '/images/20.jpg',
    width: '2',
    height: '4'
},

{
    src: '/images/02.jpg',
    width: '2',
    height: '4'
},
{
    src: '/images/25.jpg',
    width: '2',
    height: '2'
},
{ src: '/images/30.jpg' },
{
    src: '/images/40.jpg',
    width: '2',
    height: '2'
},
{
    src: '/images/50.jpg',
    width: '2',
    height: '2'
},

{
    text: 'يحيى الفخراني'
},
{
    src: '/images/60.jpg'
},
{
    src: '/images/65.jpg'
},
{
    src: '/images/70.jpg'
},
{
    src: '/images/70.jpg',
    width: '2'
},

{
    text: 'I know you dont agree with me but I agree with myself',
    width: '1'
},
{
    src: '/images/75.jpg'
},
{
    src: '/images/80.jpg'
},
{
    text: 'ش تقصد؟',
    height: '2'
},
{
    src: '/images/90.jpg',
    width: '2',
    height: '5'
},
{
    text: 'اتعلم اتعلم ...'
},
{
    src: '/images/84.jpg',
},
{
    src: '/images/86.jpg',
    height: '3'
},
{
    src: '/images/82.jpg',
    width: '2'
},
{
    text: 'هو ميسي بيلعب في برشلونة؟',
    height: '2'
},
{
    text: 'Channel, Dior, Versace .. يلا ناكل شوكولاتة؟'
},
{
    src: '/images/9010.jpg'
},

{
    src: '/images/9040.jpg'
},
{
    text: ' أتألم ولا أتكلم فلا أستطيييع'
},
{
    text: 'المشاكل لذيذة',
    width: '2'
},
{
    src: '/images/88.jpg',
    width: '3',
    height: '4'
},
{
    text: 'بذمتك كده ما عايزة...'
}, {
    text: 'الزيني بركات'
}, {
    text: 'Trontinette paton salé'
}
]