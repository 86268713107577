import * as React from 'react';
import Masonry from 'react-masonry-component';
import './Gallery.css'

const masonryOptions = {
    itemSelector: '.grid-item',
    columnWidth: '.grid-sizer',
    percentPosition: true
};

const imagesLoadedOptions = { background: '.my-bg-image-el' }

class Gallery extends React.Component {
    render() {
        const childElements = this.props.elements.map(function (element, i) {

            if (element.text) {
                const num = Math.floor(Math.random() * 3);
                const width = num > 1 ? `grid-item--width${num}` : 'grid-item'
                const height = element.height ? `grid-item--height${element.height}` : ''
                return <div className={`text-wrapper grid-item ${width} ${height}`}>
                    <span className={`text-box`}>
                        {element.text}
                    </span>
                </div>
            }
            else {
                const width = element.width ? `grid-item--width${element.width}` : 'grid-item'
                const height = element.height ? `grid-item--height${element.height}` : 'grid-item--height2'
                return (
                    <div
                        onClick={() => window.open(element.src)}
                        style={{ backgroundImage: `url(${element.src})` }}
                        className={`image grid-item ${width} ${height}`}
                    />
                );
            }
        });

        return (

            <Masonry
                className={'grid'} // default ''
                // elementType={'ul'} // default 'div'
                options={masonryOptions} // default {}
            // disableImagesLoaded={false} // default false
            // updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
            // imagesLoadedOptions={imagesLoadedOptions} // default {}
            >
                <div className="grid-sizer"></div>
                {childElements}
            </Masonry>
        );
    }
}

export default Gallery;